@import url(https://fonts.googleapis.com/css?family=Noto+Serif);
@import 'bootstrap-variables';
@import '~bootstrap/scss/bootstrap';

@import 'final-form';

body {
  font-family: "Noto Serif", serif;
  margin: 0;
  height: 100vh;
  color: $primary;
}

.header {
  border-bottom: 2px solid $primary;
  height: 100px;
  display: flex;
  justify-content: space-between;
  .logo {
    img {
      height: 98px;
    }
  }
  .menu {
    align-self: center;
    margin-right: 1rem;
  }
}

.app {
  height: calc(100vh - 100px);
}

.home {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 11vw;
  line-height: 1;
  padding: 0;
  margin: 0;
  font-weight: normal;
  text-align: center;
}